import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home/index.vue";

Vue.use(VueRouter);

// 路由重复hack操作
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/pdf-to-word"
  },
  {
    name: "pdf-to-word",
    path: "/pdf-to-word",
    component: () =>
      import(/* webpackChunkName: "convert"*/ "@/views/PdfToWord"),
    meta: {
      title: "PDF转Word_在线PDF转换成Word_pdf在线转换-PDF文档在线转换",
      content: {
        keywords:
          "pdf转word,pdf怎么转换成word,在线pdf转word转换器免费版,pdf转换成word,pdf转docx,,pdf转doc,pdf转word在线,pdf转word免费的软件",
        description:
          "PDF在线转换转换提供PDF转Word、PDF转换成Word、在线pdf转word转换器等在线转换服务，文档转换精准，无需二次编辑，非常易于使用。"
      }
    }
  },
  {
    name: "pdf-to-excel",
    path: "/pdf-to-excel",
    component: () =>
      import(/* webpackChunkName: "convert"*/ "@/views/PdfToExcel"),
    meta: {
      title: "PDF转Excel_在线PDF转换成Excel_pdf在线转换-PDF文档在线转换",
      content: {
        keywords:
          "pdf转Excel,pdf转换成excel,pdf怎么转换成Excel,在线pdf转excel表格,pdf转xlsx,pdf转xls,pdf转excel在线,pdf转excel怎么转",
        description:
          "PDF在线转换转换提供PDF转Excel、PDF转换成Excel、在线pdf转excel表格等在线转换服务，文档转换精准，无需二次编辑，非常易于使用。"
      }
    }
  },
  {
    name: "pdf-to-ppt",
    path: "/pdf-to-ppt",
    component: () =>
      import(/* webpackChunkName: "convert"*/ "@/views/PdfToPPT"),
    meta: {
      title: "PDF转PPT_在线PDF转换成PPT_pdf在线转换-PDF文档在线转换",
      content: {
        keywords:
          "pdf转PPT,pdf转换成PPT,pdf怎么转换成PPT,在线pdf转ppt转换器,pdf转pptx,pdf转PPT在线,pdf转PPT怎么转",
        description:
          "PDF在线转换转换提供PDF转PPT、PDF转换成PPT、在线pdf转ppt转换器等在线转换服务，文档转换精准，无需二次编辑，非常易于使用。"
      }
    }
  },
  {
    name: "pdf-to-img",
    path: "/pdf-to-img",
    component: () =>
      import(/* webpackChunkName: "convert"*/ "@/views/PdfToImg"),
    meta: {
      title: "PDF转jpg_PDF转图片_在线PDF转图片_pdf在线转换-PDF文档在线转换",
      content: {
        keywords:
          "pdf转jpg,pdf转换成jpg,pdf转图片,pdf转jpg怎么转,在线pdf转图片,pdf怎么转为图片",
        description:
          "PDF在线转换转换提供PDF转图片、PDF转换成jpg、在线PDF转换成图片等在线转换服务，图片清晰度高，非常易于使用。"
      }
    }
  },
  {
    name: "cad-to-pdf",
    path: "/cad-to-pdf",
    component: () =>
      import(/* webpackChunkName: "convert"*/ "@/views/CadToPdf"),
    meta: {
      title: "CAD转PDF_在线CAD转PDF_pdf在线转换-PDF文档在线转换",
      content: {
        keywords:
          "cad转pdf,cad转pdf在线,DWG转PDF,cad转换成pdf格式,cad转pdf怎么转",
        description:
          "PDF在线转换转换提供CAD转PDF、在线cad转pdf等在线转换服务，可以在线将CAD转换为pdf,非常易于使用。"
      }
    }
  },
  {
    name: "ofd-to-pdf",
    path: "/ofd-to-pdf",
    component: () =>
      import(/* webpackChunkName: "convert"*/ "@/views/OfdToPdf"),
    meta: {
      title: "OFD转PDF_在线OFD转PDF_pdf在线转换-PDF文档在线转换",
      content: {
        keywords: "OFD转pdf,ofd转换成pdf,ofd转换成pdf格式,ofd转pdf怎么转",
        description:
          "PDF在线转换转换提供OFD转PDF、在线ofd转pdf等在线转换服务，可以在线将OFD转换为pdf,非常易于使用。"
      }
    }
  },
  {
    name: "img-to-pdf",
    path: "/img-to-pdf",
    component: () =>
      import(/* webpackChunkName: "convert"*/ "@/views/ImgToPdf"),
    meta: {
      title: "图片转PDF_JPG转PDF_在线图片转PDF_pdf在线转换-PDF文档在线转换",
      content: {
        keywords:
          "图片转pdf,jpg转pdf,在线图片转PDF,jpg怎么转换成pdf,png转pdf,bmp转pdf",
        description:
          "PDF在线转换转换提供图片转PDF、JPG转PDF等在线转换服务，可以在线将图片格式转换为pdf,非常易于使用。"
      }
    }
  },
  {
    name: "word-to-pdf",
    path: "/word-to-pdf",
    component: () =>
      import(/* webpackChunkName: "convert"*/ "@/views/WordToPdf"),
    meta: {
      title:
        "Word转PDF_在线Word转PDF_Word转换成PDF_pdf在线转换-PDF文档在线转换",
      content: {
        keywords:
          "Word转PDF,Word怎么转换成PDF,word转pdf在线,word转换成pdf转换器,在线word转换成pdf,doc转pdf",
        description:
          "PDF在线转换转换提供Word转PDF、在线Word转PDF、在线word转pdf转换器等在线转换服务，可以在线将Word转换成PDF,非常易于使用。"
      }
    }
  },
  {
    name: "excel-to-pdf",
    path: "/excel-to-pdf",
    component: () =>
      import(/* webpackChunkName: "convert"*/ "@/views/ExcelToPdf"),
    meta: {
      title:
        "Excel转PDF_在线Excel转PDF_Excel转换成PDF_pdf在线转换-PDF文档在线转换",
      content: {
        keywords:
          "Excel转PDF,Excel怎么转换成PDF,Excel转pdf在线,Excel转换成pdf转换器,在线Excel转换成pdf,xls转pdf",
        description:
          "PDF在线转换转换提供Excel转PDF、在线Excel转PDF、在线Excel转pdf转换器等在线转换服务，可以在线将Excel转换成PDF,非常易于使用。"
      }
    }
  },
  {
    name: "ppt-to-pdf",
    path: "/ppt-to-pdf",
    component: () =>
      import(/* webpackChunkName: "convert"*/ "@/views/PPTToPdf"),
    meta: {
      title:
        "PPT转PDF_在线PPT转PDF_PPT转换成PDF_pdf在线转换-PDF文档在线转换",
      content: {
        keywords:
          "PPT转PDF,PPT怎么转换成PDF,PPT转pdf在线,PPT转换成pdf转换器,在线PPT转换成pdf",
        description:
          "PDF在线转换转换提供PPT转PDF、在线PPT转PDF、在线PPT转pdf转换器等在线转换服务，可以在线将PPT转换成PDF,非常易于使用。"
      }
    }
  },
  {
    name: "history",
    path: "/history",
    component: () =>
      import(/* webpackChunkName: "history"*/ "@/views/History"),
    meta: {
      title: "转换记录-DFP在线转换",
      content: {
        keywords:
          "PDF转Word,在线PDF转Word,pdf转ppt,pdf转jpg,pdf转excel免费转换器,cad转pdf,ofd转PDF,pdf转换器,PDF在线转换,pdf转换成word转换器在线免费",
        description:
          "DFP在线转换为你提供全方位PDF转换，可实现PDF转换成Word,word转换成pdf,ppt转换成pdf,在线PDF转换成PPT,PDF转换成Excel,PDF转图片的在线转换服务，轻松解决PDF所有问题!"
      }
    }
  },
  {
    name: "vip",
    path: "/vip",
    component: () => import(/* webpackChunkName: "vip"*/ "@/views/Vip"),
    meta: {
      title: "开通会员-DFP在线转换",
      content: {
        keywords:
          "PDF转Word,在线PDF转Word,pdf转ppt,pdf转jpg,pdf转excel免费转换器,cad转pdf,ofd转PDF,pdf转换器,PDF在线转换,pdf转换成word转换器在线免费",
        description:
          "DFP在线转换为你提供全方位PDF转换，可实现PDF转换成Word,word转换成pdf,ppt转换成pdf,在线PDF转换成PPT,PDF转换成Excel,PDF转图片的在线转换服务，轻松解决PDF所有问题!"
      }
    }
  },
  {
    name: "product",
    path: "/product",
    component: () =>
      import(/* webpackChunkName: "product"*/ "@/views/Product"),
    meta: {
      title: "套餐介绍-DFP在线转换",
      content: {
        keywords:
          "PDF转Word,在线PDF转Word,pdf转ppt,pdf转jpg,pdf转excel免费转换器,cad转pdf,ofd转PDF,pdf转换器,PDF在线转换,pdf转换成word转换器在线免费",
        description:
          "DFP在线转换为你提供全方位PDF转换，可实现PDF转换成Word,word转换成pdf,ppt转换成pdf,在线PDF转换成PPT,PDF转换成Excel,PDF转图片的在线转换服务，轻松解决PDF所有问题!"
      }
    }
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () =>
      import(/* webpackChunkName: "privacy"*/ "@/views/privacy"),
    meta: {
      title: "隐私政策-DFP在线转换",
      content: {
        keywords:
          "PDF转Word,在线PDF转Word,pdf转ppt,pdf转jpg,pdf转excel免费转换器,cad转pdf,ofd转PDF,pdf转换器,PDF在线转换,pdf转换成word转换器在线免费",
        description:
          "DFP在线转换为你提供全方位PDF转换，可实现PDF转换成Word,word转换成pdf,ppt转换成pdf,在线PDF转换成PPT,PDF转换成Excel,PDF转图片的在线转换服务，轻松解决PDF所有问题!"
      }
    }
  },
  {
    name: "licence",
    path: "/licence",
    component: () =>
      import(/* webpackChunkName: "licence"*/ "@/views/licence"),
    meta: {
      title: "用户协议-DFP在线转换",
      content: {
        keywords:
          "PDF转Word,在线PDF转Word,pdf转ppt,pdf转jpg,pdf转excel免费转换器,cad转pdf,ofd转PDF,pdf转换器,PDF在线转换,pdf转换成word转换器在线免费",
        description:
          "DFP在线转换为你提供全方位PDF转换，可实现PDF转换成Word,word转换成pdf,ppt转换成pdf,在线PDF转换成PPT,PDF转换成Excel,PDF转图片的在线转换服务，轻松解决PDF所有问题!"
      }
    }
  },
  {
    name: "vipserver",
    path: "/vipserver",
    component: () =>
      import(/* webpackChunkName: "vipserver"*/ "@/views/VipServe"),
    meta: {
      title: "会员服务条款-DFP在线转换",
      content: {
        keywords:
          "PDF转Word,在线PDF转Word,pdf转ppt,pdf转jpg,pdf转excel免费转换器,cad转pdf,ofd转PDF,pdf转换器,PDF在线转换,pdf转换成word转换器在线免费",
        description:
          "DFP在线转换为你提供全方位PDF转换，可实现PDF转换成Word,word转换成pdf,ppt转换成pdf,在线PDF转换成PPT,PDF转换成Excel,PDF转图片的在线转换服务，轻松解决PDF所有问题!"
      }
    }
  }
];

const isProd = process.env.NODE_ENV === "production";
const isFlag = process.env.VUE_APP_flag === "pro";
const router = new VueRouter({
  mode: "hash",
  // mode: "history",
  base: isProd && isFlag ? "/" : process.env.BASE_URL,
  // base: isProd && isFlag ? process.env.BASE_URL : process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = document.body.scrollTop = 0;

  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (to.meta.content) {
    const head = document.getElementsByTagName("head");
    const meta = document.createElement("meta");

    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", to.meta.content.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", to.meta.content.description);
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  next();
});

export default router;
