import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import {
  Icon,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Checkbox,
  Dialog,
  Tabs,
  TabPane,
  Form,
  FormItem,
  Input,
  Upload,
  Button,
  Message,
  Progress,
  Select,
  Option,
  // Carousel,
  // CarouselItem
  Loading,
  MessageBox,
  Table,
  TableColumn,
  Pagination,
  Rate,
  Tooltip
} from "element-ui";

import "@/styles/index.scss";
Vue.use(Option);
Vue.use(Select);
Vue.use(Upload);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Checkbox);
Vue.use(Dialog);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Progress);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Rate);
Vue.use(Tooltip);

Vue.config.productionTip = false;
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;
new Vue({
  router,
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
  render: (h) => h(App)
}).$mount("#app");
